<template>
  <Account />
</template>

<script>
import Account from '../components/Account'

export default {
  name: 'AccountView',

  components: {
    Account
  }
}
</script>
